import React, { useEffect, useState } from "react";
import "./App.css";
import logo from "./img/logo.png";
import axios from 'axios';

function useForceUpdate() {
  const [value, setValue] = useState(0);
  return () => setValue((value) => value + 1);
}

export default function App() {
  const forceUpdate = useForceUpdate();
  const [sdbvers, setvers] = useState({
    toolbox: "latest",
    studio: "latest",
    client: "latest",
    server: "latest",
  });
  const [status, setstatus] = useState("idle");
  const [showstatus, setshowstatus] = useState(false);
  let link_base = "https://release.memsql.com/production/index/";
  let server_link = "memsqlserver/";
  let studio_link = "memsqlstudio/";
  let client_link = "memsqlclient/";
  let toolbox_link = "memsqltoolbox/";
  const [jsons, setjsons] = useState((prevState) => ({
    ...prevState,
    toolbox: {
      link: link_base + toolbox_link,
      json: {},
      versions: [],
    },
    studio: {
      link: link_base + studio_link,
      json: {},
      versions: [],
    },
    client: {
      link: link_base + client_link,
      json: {},
      versions: [],
    },
    server: {
      link: link_base + server_link,
      json: {
        packages: {},
      },
      versions: [],
    },
  }));
  const software_names = Object.keys(jsons);
  const [opsopen, setopsopen] = useState(false);
  const [opsservver, setopsservver] = useState("6.8.24");
  const [opsservdl, setopsservdl] = useState(
    "https://download.memsql.com/releases/version/6.8.24/memsqlbin_amd64.tar.gz"
  );

  const opsLink = (e) => {
    e.preventDefault();
    let opsdlstring =
      "https://download.memsql.com/releases/version/" +
      opsservver +
      "/memsqlbin_amd64.tar.gz";
    setopsservdl(opsdlstring);
  };

  const handleDownload = async (link) => {
    setstatus("Starting download.");
    const a = document.createElement("a");
    a.href = link;
    a.download = a.href.split("/").pop();
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const getJsons = async (sdbsoftware) => {
    setstatus("Acquiring JSON.");
    let newlink = jsons[sdbsoftware]["link"] + sdbvers[sdbsoftware] + ".json";
    let dl_json = jsons;
    try {
      dl_json[sdbsoftware]["json"] = await axios.get(newlink).then((res) => {
        setstatus("Got JSON.");
        return res.data;
      });
    } catch (error) {
      setstatus("Error getting JSON: ", error);
      console.log(error);
    }
    setstatus("Loading JSON.");
    setjsons(dl_json);
    forceUpdate();
    setstatus("JSON loaded. Links below.");
  };

  const changeHandler = (what, change) => {
    setstatus("Version change detected.");
    let tempvers = sdbvers;
    tempvers[what] = change;
    setvers(tempvers);
    setstatus("Starting JSON acquisition...");
    getJsons(what);
  };

  const initializeLatest = async () => {
    setstatus("Initializing...");
    setstatus("Grabbing versions json...");
    software_names.map(async (val) => {
      grabVersions(val);
      changeHandler(val, "latest");
    });
  };

  const versionGrab = async (software) => {
    let something = await axios
      .get(link_base + software + "/versions.json")
      .then((res) => {
        return res["data"]["versions"]
      })
      .catch((error) => {
        console.log(error);
        setstatus("Initialization error. Check console.");
      });
    return something
  };

  const grabVersions = async (software) => {
    let newjson = jsons;
    switch (software) {
      case "toolbox":
        newjson[software]["versions"] = await versionGrab("memsqltoolbox");
        break;
      case "studio":
        newjson[software]["versions"] = await versionGrab("memsqlstudio");
        break;
      case "client":
        newjson[software]["versions"] = await versionGrab("memsqlclient");
        break;
      case "server":
        newjson[software]["versions"] = await versionGrab("memsqlserver");
        break;
      default:
        break;
    }
    setjsons(newjson);
  };

  useEffect(() => {
    initializeLatest();
  }, []);

  return (
    <div className="sdb-app">
      <div id="top-section">
        <div id="top-bar">
          <img
            src={logo}
            alt="SingleStore Logo"
            onClick={() => setshowstatus(!showstatus)}
          />
        </div>
        <h1>SDB-Download</h1>
      </div>
      <div id="contentcontainer">
        {jsons.server ? (
          <div id="download-section">
            <form>
              <div id="versionselect">
                <label>Toolbox version: </label>
                <select
                  id="sdbvers"
                  name="sdbvers"
                  onChange={async (e) => {
                    changeHandler("toolbox", e.target.value);
                  }}
                >
                  {jsons["toolbox"]["versions"].map((val) => {
                    return (
                      <option key={val + "key"} value={val}>
                        {val}
                      </option>
                    );
                  })}
                </select>
                <label>Studio version: </label>
                <select
                  id="sdbvers"
                  name="sdbvers"
                  onChange={async (e) => {
                    changeHandler("studio", e.target.value);
                  }}
                >
                  {jsons["studio"]["versions"].map((val) => {
                    return (
                      <option key={val + "key"} value={val}>
                        {val}
                      </option>
                    );
                  })}
                </select>
                <label>Client version: </label>
                <select
                  id="sdbvers"
                  name="sdbvers"
                  onChange={async (e) => {
                    changeHandler("client", e.target.value);
                  }}
                >
                  {jsons["client"]["versions"].map((val) => {
                    return (
                      <option key={val + "key"} value={val}>
                        {val}
                      </option>
                    );
                  })}
                </select>
                <label>Server version: </label>
                <select
                  id="sdbvers"
                  name="sdbvers"
                  onChange={async (e) => {
                    changeHandler("server", e.target.value);
                  }}
                >
                  {jsons["server"]["versions"].map((val) => {
                    return (
                      <option key={val + "key"} value={val}>
                        {val}
                      </option>
                    );
                  })}
                </select>
              </div>
            </form>
            <div id="statusdiv">{status}</div>
            <div id="opsholder">
              <button id="needops" onClick={() => setopsopen(!opsopen)}>
                I need Ops...
              </button>
              {opsopen ? (
                <div id="opsinfo">
                  <h2>MemSQL (SingleStore) Ops</h2>
                  <p id="curdllink">
                    Note: Ops is EOL and incompatible with server versions 7.5+.
                    This tool will not check the validity of the server binary
                    link (it will just form it).
                  </p>
                  <div id="opsdownload">
                    <div>
                      <h4>Ops 7.0.7 (Latest)</h4>
                      <div id="curdllink">
                        <a href="http://download.memsql.com/memsql-ops-7.0.7/memsql-ops-7.0.7.tar.gz">
                          Download
                        </a>
                        http://download.memsql.com/memsql-ops-7.0.7/memsql-ops-7.0.7.tar.gz
                      </div>
                      <div>
                        <h4>Ops Server Binaries</h4>
                        <div id="curdllink">
                          <a href={opsservdl}>Download</a>
                          {opsservdl}
                        </div>
                        <form onSubmit={(e) => opsLink(e)}>
                          <label>Enter Server Version:</label>
                          <input
                            value={opsservver}
                            onChange={(e) => setopsservver(e.target.value)}
                          ></input>
                          <button>Request Link</button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            <div id="display_area">
              {software_names.map((val) => {
                return (
                  <div
                    className="software_div"
                    id={val + "_dis"}
                    key={val + "key_dis"}
                  >
                    <h2>SingleStore {val}</h2>
                    {jsons[val]["json"]["packages"] ? (
                      <div
                        className="package_div"
                        id={val + "_pak"}
                        key={val + "key_pak"}
                      >
                        {Object.keys(jsons[val]["json"]["packages"]).map(
                          (jpm) => {
                            let cur_dl =
                              "https://release.memsql.com/" +
                              jsons[val]["json"]["packages"][jpm]["Path"];
                            return (
                              <div id="dlarea" key={val + jpm + "key"}>
                                <div id="pkgdl">
                                  <h3>{jpm}</h3>{" "}
                                  <p id="versdisplay">{sdbvers[val]}</p>
                                  <button
                                    onClick={() => handleDownload(cur_dl)}
                                  >
                                    Download
                                  </button>
                                </div>
                                <div id="curdllink">{cur_dl}</div>
                                <br></br>
                                <div id="curdllink">{jsons[val]["json"]["packages"][jpm]["Sha256Sum"]}</div>
                              </div>
                            );
                          }
                        )}
                      </div>
                    ) : null}
                  </div>
                );
              })}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
